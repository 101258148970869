import React, { useEffect, useState } from 'react';
import * as Tone from 'tone';

import sound1 from './../assets/flute.wav';
import sound2 from './../assets/gregorian-chants.wav';
import sound3 from './../assets/rain-ambience.wav';
import sound4 from './..//assets/waves-sand-beach.wav';

const Soundscape = () => {
  const [volume, setVolume] = useState(0);
  const [pitch, setPitch] = useState(1);
  const [isPlaying, setIsPlaying] = useState({
    sound1: false,
    sound2: false,
    sound3: false,
    sound4: false,
  });

  useEffect(() => {
    const players = {
      sound1: new Tone.Player(sound1).toDestination(),
      sound2: new Tone.Player(sound2).toDestination(),
      sound3: new Tone.Player(sound3).toDestination(),
      sound4: new Tone.Player(sound4).toDestination(),
    };

    Object.values(players).forEach(player => {
      player.volume.value = volume;
      player.playbackRate = pitch;
    });

    Tone.loaded().then(() => {
      Object.entries(isPlaying).forEach(([sound, shouldPlay]) => {
        if (shouldPlay) {
          players[sound].start();
        } else {
          players[sound].stop();
        }
      });
    });

    return () => {
      Object.values(players).forEach(player => player.dispose());
    };
  }, [volume, pitch, isPlaying]);

  return (
    <div>
      <div>Soundscape</div>
      <input type="range" min="-30" max="0" value={volume} onChange={e => setVolume(e.target.value)} />
      <input type="range" min="0.5" max="2" step="0.1" value={pitch} onChange={e => setPitch(e.target.value)} />
      {Object.entries(isPlaying).map(([sound, shouldPlay]) => (
        <button onClick={() => setIsPlaying(prev => ({ ...prev, [sound]: !shouldPlay }))}>
          {shouldPlay ? 'Stop' : 'Play'} {sound}
        </button>
      ))}
    </div>
  );
};

export default Soundscape;